//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import translations from './translation.json'
import translations from "@/assets/translations.json";
export default {
    data() {
        return {
            tabBarIndex: 0,
            tabBarList: [
                {
                    label: 'ALL',
                    params: {}
                },
                {
                    label: 'waitPay',
                    params: {
                        order_status: 2,
                        tab: 'waitPay'
                    }
                },
                {
                    label: 'waitSend',
                    params: {
                        order_status: 4,
                        tab: 'waitSend'
                    }
                },
                {
                    label: 'waitConfirm',
                    params: {
                        order_status: 6,
                        tab: 'waitConfirm'
                    }
                },
                {
                    label: 'waitComment',
                    params: {
                        is_comment: 0,
                        tab: 'waitComment'
                    }
                },
			],
            orderList: [],
            total: -1,
            page: 1,
            locale: window.localStorage.getItem('locale') || this.$i18n.locale,
        };
    },

    created() {},

    methods: {
        // // 获取订單统计
        // async getOrderStatistics() {
        //     const res = await uni.$http.get('/order.statistics')
        //     if (res.data.code !== 0) {
        //         if (res.data.code === 401) this.tabBarList1.forEach(item => item.count = 0)
        //         return
        //     }
        //     // let info = this.loginUserInfo
        //     let data = res.data.data
        //     this.tabBarList1.forEach(item => {
        //         if (data[item.tabCode] !== undefined) item.count = data[item.tabCode]
        //     })
        // },
        translateGoodsName(goodsName) {
            if (this.locale === 'EN') {
                return translations[goodsName] || goodsName; // Use translations from JSON
            }
            return goodsName; // Return original name if locale is not 'EN'
        },
        translateOrderStatus(statusText) {
        if (this.locale === 'EN') {
            const statusTranslations = {
                '待支付': 'Pending Payment',
                '已支付': 'Paid',
                '已取消': 'Cancelled',
                '已完成': 'Completed',
                // Add more status translations here
            };
            return statusTranslations[statusText] || statusText;
        }
        return statusText;
    },
        //获取訂單列表
        async getOrderList(page) {
            page && (this.page = 1)
            // if (this.total >= this.orderList.length) return
            const res = await this.$http.get('/order', {
                page: this.page++,
                //uniacid: 19,
                uniacid: parseInt(window.localStorage.getItem('uniacid')),
                ...this.tabBarList[this.tabBarIndex].params
            })
            if (res.code !== 0) {
        let errorMessage = res.message;



        // Translate the error message if locale is 'EN' and message contains '請先登錄'
        if (this.locale.toUpperCase() === 'EN' && errorMessage.includes('請先登錄')) {
            errorMessage = 'Please login first';
        }

        return this.$message.error(errorMessage); // Show the translated message or the original one
    }
            let data = res.data
            this.total = data.total
            this.orderList = [...data.list, ...this.orderList]
            console.log("uniacid: " + this.uniacid)
        },

        // 取消订單
		orderCancel(order_sn) {
			this._doConfirmAndRequest('確定要取消吗？', '/order/cancel', order_sn)
		},
		async orderCancel1(order_sn){
			  //let _this = this
			  //_this.$message({message: '請登錄APP取消訂單'})    
               // console.log("order_sn: " + order_sn);

                this.$confirm("確定要退款嗎", '提示', {
                    confirmButtonText: '確定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.applyRefund(order_sn)
                }).catch(() => {
                    this.$message({message: '已取消'})
                })


                
                



		},
        async applyRefund(order_sn){
            const res = await this.$http.get('/order/detail', {
					order_sn: order_sn
				})
				//if (res.data.code !== 0) {
				///	return this.$message.error(res.data);
				//}
				let data = res.data
				let refundList = []
				console.log(data);
				let goodsList = data.goods.data.list;
              //  console.log(goodsList);

                if (goodsList) {
					goodsList.forEach(item => {

							if (item.refund_status < 1) {
								refundList.push({
									form: {
										amount: item.goods_amount,
										reason: '',
										images: [],
										description: '',
										order_goods_id: item.order_goods_id
									},
									goods: [item]
								})
							}

					})
				}else{
					refundList.push({
						form: {
							amount: data.basic.data.field.order_amount,
							reason: '',
							images: [],
							description: '',
							order_goods_id: ''
						},
						goods: goodsList
					})
				}
				

              //  console.log("refundList")
             // console.log(refundList)

              let refund = []
				refundList.forEach(item => refund.push(item.form))
				const res2 = await this.$http.post('/order/refund', {
					order_sn: order_sn,
					refund: JSON.stringify(refund)
				})
				if (res2.data.code !== 0) {
                    this.$message({message: res2.message, type: 'success'})
                    this.getOrderList(1)
				}

        },

        //滚动加载
        load() {
            this.getOrderList()
        },

        // 提示并請求
        _doConfirmAndRequest(content, api, order_sn) {
            let _this = this
            this.$confirm(content, '提示', {
                confirmButtonText: '確定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$http.post(api, {
                    order_sn,
                }).then(res => {
                    if(res.code !== 0) {
                        return _this.$message.error(res.message);
                    }else{
                        _this.$message({message: res.message, type: 'success'})
                        _this.getOrderList(1)

                    }
                })
            }).catch(() => {
                 _this.$message({message: '已取消'})    
            })
        },

        //訂單详情页面
        goDetail(order) {
             this.$router.push({ name: 'OrderDteail', params:{order}})
        },
        
    },
    watch: {
        tabBarIndex:function(val){
            this.orderList = []
            this.total = -1
            this.page = 1
            this.getOrderList()
        }
    }
};
